import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Logo from "../../../assets/imgs/psyder-logo.png";
import "./Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  const linkSections = [
    {
      title: "Sobre Psyder",
      links: [
        { href: "/sobre-psyder", text: "Sobre Psyder" },
        { href: "/#mision", text: "Misión" },
        { href: "/como-funciona", text: "Cómo funciona" },
      ],
    },
    {
      title: "Servicios",
      links: [
        { href: "/#precios", text: "Precios" },
        { href: "/#contacto", text: "Newsletter" },
        { href: "mailto:conecta@psyder.xyz", text: "Contacto" },
      ],
    },
    {
      title: "Legal",
      links: [
        { href: "#aviso-legal", text: "Aviso Legal" },
        { href: "#politica-privacidad", text: "Política de Privacidad" },
        { href: "#terminos-condiciones", text: "Términos y Condiciones de Uso" },
      ],
    },
  ];

  return (
    <footer className="pt-5">
      <Container>
        <Row className="mb-4">
          <Col lg={3} className="text-start mb-lg-0 mb-3">
            <a href="/">
              <img src={Logo} alt="Psyder Logo" width="200" height="60" />
            </a>
          </Col>
          {linkSections.map((section, index) => (
            <Col lg={3} md={4} key={index} className="text-start">
              <ul className="list-unstyled">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a href={link.href} aria-label={link.text}>
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
        <Row>
          <Col className="text-center pb-2">
            <p className="mb-0 small text-dark">&copy; {currentYear} Psyder. Todos los derechos reservados.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;

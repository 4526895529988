import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import useContactForm from "../../../hooks/useContactForm";

function Contact() {
  const { formData, handleChange, handleSubmit } = useContactForm();
  const formFields = [
    { id: "fullName", label: "Nombre completo*", type: "text" },
    { id: "email", label: "E-mail*", type: "email" },
    { id: "phone", label: "Teléfono", type: "tel" },
  ];

  return (
    <Container>
      <Row className="section-title">
        <Col>
          <h2>Suscríbete y No Te Pierdas Ninguna Novedad</h2>
          <h3>Sé el primero en enterarte de las últimas funcionalidades de Psyder, recibir consejos de expertos y acceder a oportunidades exclusivas de formación para psicoterapeutas.</h3>
        </Col>
      </Row>
      <Row className="section-content justify-content-center">
        <Col lg={8}>
          <Card className="contact-card">
            <Card.Body className="p-lg-5 p-md-4 p-2">
              <Form onSubmit={handleSubmit}>
                {formFields.map((field) => (
                  <Form.Group as={Row} className="mb-3" controlId={field.id} key={field.id}>
                    <Form.Label column sm={4} className="prim-color fw-bold text-start">
                      {field.label}
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type={field.type} name={field.id} value={formData[field.id]} onChange={handleChange} required={field.id !== "phone"} />
                    </Col>
                  </Form.Group>
                ))}
                <Form.Group as={Row} className="mb-3" controlId="topic">
                  <Form.Label column sm={4} className="prim-color fw-bold text-start">
                    Interés*
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control as="select" name="topic" value={formData.topic} onChange={handleChange} required>
                      <option value="" disabled hidden></option>
                      <option value="psicoterapeuta">Soy Psicoterapeuta y quiero recibir Recursos para Crecer Profesionalmente</option>
                      <option value="terapia">Quiero saber más para Iniciar una Terapia Psicológica</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group className="py-3">
                  <Form.Check
                    type="checkbox"
                    id="acceptCommunications"
                    name="acceptCommunications"
                    label="Acepto recibir comunicaciones de Psyder. Puedo cancelar la suscripción en cualquier momento."
                    required
                    checked={formData.acceptCommunications}
                    onChange={handleChange}
                  />
                </Form.Group>
                <div className="text-center">
                  <Button className="btn-prim mt-3" type="submit">
                    Recibe Novedades Exclusivas – Suscríbete Ahora
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;

import { useState } from "react";
import { db } from "../config/firebase";
import { addDoc, collection } from "firebase/firestore"; 

const saveContactForm = async (formData) => {
  try {
    const docRef = await addDoc(collection(db, "contactForm"), {
      ...formData,
      createdAt: new Date(),
    });
    return { success: true, id: docRef.id };
  } catch (error) {
    if (process.env.NODE_ENV === "production") {
      return { success: false, error: "Error saving form data" };
    } else {
      console.error("Error saving form data:", error.message);
      return { success: false, error: error.message };
    }
  }
};

const useContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    topic: "",
    acceptCommunications: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await saveContactForm(formData);
      if (response.success) {
        console.log("Form data saved successfully");
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          topic: "",
          acceptCommunications: false,
        });
      } else {
        console.error("Error saving form data:", response.error);
      }
    } catch (error) {
      if (process.env.NODE_ENV === "production") {
        console.error("Unexpected error saving form data");
      } else {
        console.error("Unexpected error saving form data:", error.message);
      }
    }
  };

  return {
    formData,
    handleChange,
    handleSubmit,
  };
};

export default useContactForm;
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";

import "../landing/LandingPage.css";
import PsyderBanner from "../../assets/imgs/psyder-banner.webp";
import PsyderBannerFooter from "../../assets/imgs/psyder-banner-footer.webp";
import HowItWorksProfessionals from "./imgs/psyder-how-it-works-professionals.webp";
import HowItWorksPatients from "./imgs/psyder-how-it-works-patients.webp";

function HowItWorksHelmet({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>¿Cómo funciona Psyder? - Descubre nuestro Sistema avanzado de cribado</title>
        <meta
          name="description"
          content="Descubre cómo Psyder utiliza un sistema avanzado de cribado basado en Inteligencia Artificial, centrado en las personas, para conectar a cada usuario con el terapeuta adecuado según sus necesidades específicas. Regístrate como psicoterapeuta o usuario y accede a emparejamientos personalizados que garantizan una experiencia terapéutica efectiva y segura"
        />
        <meta
          name="keywords"
          content="Psyder, Inteligencia Artificial, emparejamiento psicoterapeutas, valoración psicológica, cribado avanzado, terapia psicológica, salud mental, especialización terapeutas, asesoramiento psicoterapeutas, formación continua psicoterapia, matching terapeuta paciente, chat anónimo psicoterapeutas"
        />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

function HowItWorks() {
  return (
    <HowItWorksHelmet>
      <section className="info-pages">
        <div className="wrapper-banner">
          <img src={PsyderBanner} alt="Psyder Banner" className="img-fluid" />
        </div>
        <Container>
          <Row className="section-title">
            <Col>
              <h2>La Evolución de la Terapia Psicológica</h2>
            </Col>
          </Row>
          <Row className="section-content">
            <Col lg={12}>
              <p>
                La <strong>Inteligencia Artificial</strong> sólo es una herramienta. La verdadera esencia del método de valoración y emparejamiento de <strong>Psyder</strong> radica en la estructura y
                conocimiento que contiene. Hemos ideado un avanzado sistema de <strong>cribado</strong> centrado en las <strong>personas</strong>, no en diagnósticos aislados. A través de un análisis
                exhaustivo de factores como síntomas psicológicos, trastornos mentales, técnicas de tratamiento y tipos de terapia, logramos extraer un grupo reducido de <strong>dimensiones</strong>{" "}
                que reflejan de forma precisa las <strong>necesidades</strong> de cada persona. Además de estas dimensiones, también consideramos las circunstancias específicas que pueden ser foco de
                problemas, el estilo personal de cada terapeuta, y la etapa vital de la persona, garantizando así un <strong>emparejamiento</strong> que va más allá de lo superficial y responde a la
                realidad de cada caso.
              </p>
            </Col>
          </Row>
          <Row className="section-content">
            <Col lg={6} className="d-flex flex-column align-items-center justify-content-center gap-3 my-4 my-lg-0">
              <img src={HowItWorksProfessionals} alt="Ilustración" className="img-fluid" />
            </Col>
            <Col lg={6}>
              <p>
                Al registrarse, los <strong>psicoterapeutas</strong> tienen la oportunidad de destacar sus áreas de <strong>especialización</strong> seleccionando un número limitado de dimensiones de
                tratamiento. Esto no solo asegura que los profesionales puedan ofrecer sus mejores habilidades, sino que también facilita que el emparejamiento sea certero. Una vez registrados, los
                terapeutas acceden a un perfil completo con diversas herramientas:
              </p>
              <ul>
                <li>
                  <strong>Publicaciones en el Blog:</strong> Comparte tus conocimientos subiendo artículos y otros archivos para su revisión y posterior publicación en el Blog de Psyder, además de
                  estar visible para los visitantes de tu perfil.
                </li>
                <li>
                  <strong>Grupos de Trabajo:</strong> Conecta con otros terapeutas en grupos especializados, intercambiando conocimientos y colaborando en temas de interés compartido.
                </li>
                <li>
                  <strong>Formación Continua:</strong> Accede a oportunidades de desarrollo profesional a través de cursos online, webinars, ponencias, y mucho más, para seguir mejorando en tu
                  práctica clínica.
                </li>
                <li>
                  <strong>Asesoramiento Personalizado:</strong> Solicita asesoramiento de expertos en áreas específicas para mejorar tus habilidades y ofrecer el mejor servicio posible.
                </li>
                <li>
                  <strong>Chat Interno:</strong> Comunícate fácilmente con usuarios interesados en tus servicios y también con el equipo de Psyder para cualquier duda o asistencia que necesites.
                </li>
                <li>
                  <strong>Valoraciones accesibles:</strong> Con el consentimiento del usuario, accede a las valoraciones previas del cribado, lo que te permitirá prepararte mejor para la primera
                  sesión.
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="section-content d-flex align-items-center justify-content-center my-5">
            <Col lg={10} className="text-center">
              <Button className="btn-prim" href="/#para-psicoterapeutas">
                Únete como Psicoterapeuta
              </Button>
            </Col>
          </Row>
          <Row className="section-content">
            <Col lg={6}>
              <p>
                <strong>Para quienes buscan un terapeuta</strong>, el proceso es muy sencillo, <strong>gratuito</strong> y diseñado pensando en su comodidad y seguridad. Simplemente interactúa con
                nuestro sistema a través del chat, que de manera amigable y guiada recopilará la información necesaria para <strong>valorar</strong> las distintas dimensiones. Tras este{" "}
                <strong>breve</strong> proceso, solo será necesario realizar un <strong>registro rápido</strong>, pidiéndote los mínimos datos posibles para proteger tu <strong>privacidad</strong>.
              </p>
              <p>
                Ya completado el registro, tendrás acceso a los perfiles de los psicoterapeutas que mejor se adapten a tus <strong>necesidades específicas</strong>, basados en la valoración
                personalizada previa.
              </p>
              <p>
                A partir de ahí, puedes explorar los perfiles de los terapeutas sugeridos y comenzar un <strong>chat anónimo</strong> para tener el primer contacto. Así, aseguramos que terapeuta y
                usuario estén alineados desde el primer momento, proporcionando una <strong>experiencia más efectiva, segura y satisfactoria</strong>.
              </p>
            </Col>
            <Col lg={6} className="d-flex flex-column align-items-center justify-content-center gap-3">
              <img src={HowItWorksPatients} alt="Ilustración" className="img-fluid" />
            </Col>
          </Row>
          <Row className="section-content d-flex align-items-center justify-content-center my-5">
            <Col lg={10} className="text-center">
              <Button className="btn-prim" href="/#para-empezar-terapia">
                Prepárate para la terapia
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="wrapper-banner">
          <img src={PsyderBannerFooter} alt="Psyder Banner" className="img-fluid banner-footer" />
        </div>
      </section>
    </HowItWorksHelmet>
  );
}

export default HowItWorks;

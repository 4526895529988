import React from "react";

function LandingDivider() {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <hr className="section-divider"></hr>
        </div>
    );
}

export default LandingDivider;
import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

import "./NavigationBar.css";
import Logo from "../../../assets/imgs/psyder-logo.png";

function NavigationBar() {
  const [expanded, setExpanded] = useState(false);

  const handleNavClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <Container fluid className="nav-container fixed-top">
        <Navbar.Brand href="/">
          <img src={Logo} alt="Psyder" width="200" height="60" />
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse>
          <Nav className="ms-auto">
            <Nav.Link href="/#mision" onClick={handleNavClick}>
              Misión
            </Nav.Link>
            <Nav.Link href="/#para-psicoterapeutas" onClick={handleNavClick}>
              Para Psicoterapeutas
            </Nav.Link>
            <Nav.Link href="/#para-empezar-terapia" onClick={handleNavClick}>
              Para Empezar Terapia
            </Nav.Link>
            <Nav.Link href="mailto:conecta@psyder.xyz" onClick={handleNavClick}>
              Contacto
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;

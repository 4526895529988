import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

import VennImg from "./imgs/psyder-pacientes-venn-diagram.webp";
import FirstIcon from "./imgs/psyder-empieza-icon.webp";
import SecondIcon from "./imgs/psyder-elige-icon.webp";
import ThirdIcon from "./imgs/psyder-conecta-icon.webp";

function Patients() {
  const cards = [
    {
      id: 1,
      number: "1",
      icon: FirstIcon,
      title: "Empieza",
      text: (
        <>
          Conversa fácilmente y con total <strong>privacidad</strong> con nuestro sistema, diseñado para comprender tus <strong>necesidades específicas</strong> y recomendarte{" "}
          <strong>terapeutas especializados</strong>.
        </>
      ),
    },
    {
      id: 2,
      number: "2",
      icon: SecondIcon,
      title: "Elige",
      text: (
        <>
          Accede a una lista <strong>personalizada</strong> de psicoterapeutas altamente cualificados. Explora sus perfiles y, si tienes dudas, estamos <strong>para ayudarte</strong>.
        </>
      ),
    },
    {
      id: 3,
      number: "3",
      icon: ThirdIcon,
      title: "Conecta",
      text: (
        <>
          De forma <strong>anónima</strong> y <strong>confidencial</strong>, contacta con el terapeuta seleccionado a través del <strong>chat</strong> para agendar una cita.
        </>
      ),
    },
  ];

  return (
    <div>
      <Container>
        <Row className="section-title">
          <Col>
            <h2>Comienza Tu Camino Hacia el Bienestar con Tu Terapeuta Ideal</h2>
            <h3>
              Te conectamos de forma gratuita con el psicoterapeuta que mejor entiende tus necesidades, garantizando una terapia especializada y efectiva. El primer paso es el más difícil; darlo es un
              gran avance.
            </h3>
          </Col>
        </Row>
        <Row className="section-content">
          {cards.map((card) => (
            <Col key={card.id} lg={4} md={6} className="my-4 my-lg-0">
              <Card className="patient-card d-flex flex-column position-relative h-100">
                <div className="circle-number">
                  <span>{card.number}</span>
                </div>
                <Card.Body className="d-flex flex-column align-items-center justify-content-between text-center">
                  <div>
                    <h4 className="mb-3">{card.title}</h4>
                    <Card.Text className="mb-3">{card.text}</Card.Text>
                  </div>
                  <img src={card.icon} alt={`Icono de ${card.title}`} className="img-fluid mt-auto" width="60" height="60" />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="section-content">
          <Col className="text-center">
            <img src={VennImg} alt="Diagrama de Pacientes" className="img-fluid" width="650" height="auto" />
          </Col>
        </Row>
        <Row className="section-content">
          <Col className="text-center">
            <Button className="btn btn-prim mt-3" href="/#contacto">
              Prepárate para cuando Estemos Listos – Suscríbete Gratis Ahora
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Patients;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCflHbiDdcg9-eccjEKUBhOWbwqWFWzwPQ",
  authDomain: "psyder-e8e61.firebaseapp.com",
  projectId: "psyder-e8e61",
  storageBucket: "psyder-e8e61.appspot.com",
  messagingSenderId: "592331569927",
  appId: "1:592331569927:web:7ea1343363ab31d027e3a2",
  measurementId: "G-NNQRPW9H0T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import VennImg from "./imgs/psyder-psicoterapeutas-venn-diagram.webp";
import FirstIcon from "./imgs/psyder-conecta-icon.webp";
import SecondIcon from "./imgs/psyder-crece-icon.webp";
import ThirdIcon from "./imgs/psyder-asesoramiento-icon.webp";

function Professionals() {
  const cards = [
    {
      id: 1,
      icon: FirstIcon,
      title: "Conecta con Pacientes Afines",
      text: (
        <>
          Psyder aumentará tu <strong>visibilidad</strong> y llegarás a las personas que mejor puedas ayudar, maximizando tu <strong>impacto</strong> y posicionándote como <strong>referente</strong>{" "}
          en el campo determinado.
        </>
      ),
    },
    {
      id: 2,
      icon: SecondIcon,
      title: "Crece Profesionalmente",
      text: (
        <>
          Únete a la <strong>comunidad</strong> de Psyder, participa en grupos de trabajo, comparte en el Blog, accede a <strong>formación</strong> continua y <strong>recursos</strong> actualizados.
        </>
      ),
    },
    {
      id: 3,
      icon: ThirdIcon,
      title: "Asesoramiento Personalizado",
      text: (
        <>
          Resuelve dudas de tus terapias, accede a <strong>supervisiones</strong> de casos con expertos, y recibe <strong>acompañamiento</strong> continuo para optimizar tu práctica clínica.
        </>
      ),
    },
  ];

  return (
    <Container>
      <Row className="section-title">
        <Col>
          <h2>Lleva tu Profesión a Otro Nivel con Psyder</h2>
          <h3>
            Únete a la plataforma innovadora que te ofrece herramientas y el apoyo personalizado que necesitas para crecer como psicoterapeuta, conectando con más pacientes de tu especialidad y
            mejorando continuamente tu desempeño y satisfacción profesional y personal.
          </h3>
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col lg={6} className="text-center">
          <img src={VennImg} alt="Diagrama de Psicoterapeuta" className="img-fluid" width="650" height="auto" />
        </Col>
        <Col lg={6}>
          <div className="d-flex flex-column mt-5 mt-lg-0">
            {cards.map((card, index) => (
              <div key={index} className="step mb-3">
                <Card className="professional-card border-0">
                  <Card.Body>
                    <div className="d-flex align-items-center mb-2">
                      <img src={card.icon} alt={`Icono de ${card.title}`} className="img-fluid me-2" width="50" height="50" />
                      <h4>{card.title}</h4>
                    </div>
                    <Card.Text>{card.text}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Professionals;

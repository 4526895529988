import React, { useState } from "react";
import { Container, Row, Col, Button, Card, ToggleButtonGroup, ToggleButton } from "react-bootstrap";

function Pricing() {
  const [billingType, setBillingType] = useState("Mensual");

  const pricingPlans = [
    {
      id: 1,
      title: "Plan Base",
      monthlyOriginalPrice: "29 €/mes",
      monthlyPrice: "23 €/mes",
      annualOriginalPrice: "290 €/año",
      annualPrice: "230 €/año",
      discount: "¡21% de Descuento!",
      description: (
        <ul>
          <li>Perfil Profesional Completo</li>
          <li>Aparición en Resultados</li>
          <li>Comunicación Privada y Segura con Pacientes</li>
          <li>Acceso a Datos Relevantes y Específicos del Paciente con su Permiso</li>
          <li>Revisiones Ilimitadas de Casos con Especialistas (por mensajería interna)</li>
          <li>Resoluciones Ilimitadas de Dudas Terapéuticas (por mensajería interna)</li>
          <li>1 hora/mes de Asesoramiento Profesional Individualizado por Videollamada</li>
          <li>Acceso y Participación en 1 Grupo de Trabajo</li>
          <li>Acceso a Recursos Gratuitos y Formaciones con Suplemento</li>
        </ul>
      ),
    },
    {
      id: 2,
      title: "Plan Estándar",
      monthlyOriginalPrice: "49 €/mes",
      monthlyPrice: "37 €/mes",
      annualOriginalPrice: "490 €/año",
      annualPrice: "370 €/año",
      discount: "¡25% de Descuento!",
      description: (
        <ul>
          <li>Todo lo Incluido en el Plan Base</li>
          <li>1 hora/semana de Revisión de Casos por Videollamada con un Especialista</li>
          <li>1 hora/semana de Asesoramiento Profesional Individualizado (videollamada)</li>
          <li>Participación en 3 Grupos de Trabajo y Acceso a Consultar el resto</li>
          <li>Acceso a Recursos y Formaciones Gratuitas, con Suplemento para Cursos Avanzados</li>
          <li>Publicación Ilimitada de Artículos en el Blog de Psyder</li>
          <li>Acceso a Futuras Funcionalidades Avanzadas (con suplemento)</li>
        </ul>
      ),
    },
    {
      id: 3,
      title: "Plan Premium",
      monthlyOriginalPrice: "69 €/mes",
      monthlyPrice: "48 €/mes",
      annualOriginalPrice: "690 €/año",
      annualPrice: "480 €/año",
      discount: "¡30% de Descuento!",
      description: (
        <ul>
          <li>Todo lo Incluido en los Planes Base y Estándar</li>
          <li>7 horas/semana de Revisión de Casos por Videollamada con Especialista</li>
          <li>7 horas/semana de Asesoramiento Profesional Individualizado por Videollamada</li>
          <li>Participación en Todos los Grupos de Trabajo</li>
          <li>Acceso Completo a Todos los Recursos y Formaciones</li>
          <li>Acceso Completo y Sin Costo Adicional a Futuras Funcionalidades Avanzadas</li>
        </ul>
      ),
    },
  ];

  return (
    <Container>
      <Row className="section-title">
        <Col>
          <h2>Únete a la Evolución de la Psicoterapia con Psyder</h2>
          <h3>
            Regístrate Ahora y Aprovecha la <span className="prim-color">Oferta Pre-Lanzamiento Para Siempre</span>. Inscríbete y disfruta de una <span className="prim-color">Prueba Gratuita</span> de
            7 días desde el momento del Lanzamiento.
          </h3>
        </Col>
      </Row>
      <Row className="section-content justify-content-center">
        <Col md={5}>
          <Row>
            <Col className="d-flex align-items-end justify-content-end">
              <p className="prim-color mb-0">¡Ahorra 2 meses!</p>
            </Col>
          </Row>
          <ToggleButtonGroup type="radio" name="billingOptions" value={billingType} onChange={(value) => setBillingType(value)} className="pricing-toggle w-100 border rounded-pill">
            <ToggleButton id="tbg-radio-1" value="Mensual" className={`pricing-toggle fw-bold w-50 border-0 rounded-pill ${billingType === "Mensual" ? "active" : ""}`}>
              Mensual
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value="Anual" className={`pricing-toggle fw-bold w-50 border-0 rounded-pill ${billingType === "Anual" ? "active" : ""}`}>
              Anual
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <Row className="section-content">
        {pricingPlans.map((plan) => (
          <Col key={plan.id} lg={4} md={6} className="mb-4">
            <Card className="pricing-card h-100">
              <Card.Body className="text-center">
                <h4 className="mb-4">{plan.title}</h4>
                <Card.Text>
                  {billingType === "Mensual" ? (
                    <>
                      <del>{plan.monthlyOriginalPrice}</del>
                      <span className="prim-color d-block fw-bold">{plan.monthlyPrice}</span>
                    </>
                  ) : (
                    <>
                      <del>{plan.annualOriginalPrice}</del>
                      <span className="prim-color d-block fw-bold">{plan.annualPrice}</span>
                    </>
                  )}
                </Card.Text>
                <span className="third-color fw-bold d-block my-2">{plan.discount}</span>
                <Button href="#contacto" className="btn-prim my-3">
                  Únete Ahora
                </Button>
                <div className="mt-4 text-start">{plan.description}</div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="section-content justify-content-center">
        <Col lg={8} className="text-center">
          <p>
            Psyder está <strong>a punto de lanzarse</strong> y estamos preparando <strong>todo para ti</strong>. Cada plan está diseñado para ofrecerte <strong>flexibilidad</strong> y los{" "}
            <strong>recursos</strong> que necesites, tanto si estás comenzando como si ya tienes una carrera consolidada. Disfruta de <strong>soporte</strong> continuo, <strong>conexiones</strong>{" "}
            significativas y oportunidades de <strong>crecimiento profesional</strong>, todo en un solo lugar.
          </p>
          <Button className="btn btn-prim mt-3" href="/#contacto">
            Suscríbete a la Newsletter para Enterarte del Lanzamiento y Empezar tu Prueba Gratuita
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Pricing;

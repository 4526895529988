import React from "react";
import { Container, Row, Col, Card, Image, Button, Carousel } from "react-bootstrap";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

const testimonials = [
  {
    id: 1,
    photo: "https://via.placeholder.com/100",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.",
    name: "Psicoterapeuta 1",
    social: {
      facebook: "https://facebook.com/psicoterapeuta1",
      twitter: "https://twitter.com/psicoterapeuta1",
      linkedin: "https://linkedin.com/in/psicoterapeuta1",
    },
  },
  {
    id: 2,
    photo: "https://via.placeholder.com/100",
    text: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    name: "Psicoterapeuta 2",
    social: {
      facebook: "https://facebook.com/psicoterapeuta2",
      twitter: "https://twitter.com/psicoterapeuta2",
      linkedin: "https://linkedin.com/in/psicoterapeuta2",
    },
  },
  {
    id: 3,
    photo: "https://via.placeholder.com/100",
    text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.",
    name: "Psicoterapeuta 3",
    social: {
      facebook: "https://facebook.com/psicoterapeuta3",
      twitter: "https://twitter.com/psicoterapeuta3",
      linkedin: "https://linkedin.com/in/psicoterapeuta3",
    },
  },
];

function Testimonials() {
  return (
    <Container>
      <Row className="section-title">
        <Col>
          <h2>Una Visión Compartida</h2>
          <h3>Referentes en Psicología comparten su entusiasmo por Psyder, una plataforma diseñada para evolucionar la experiencia terapéutica.</h3>
        </Col>
      </Row>
      <Row className="section-content">
        <Col xs={12} className="d-block d-md-none">
          <Carousel>
            {testimonials.map((testimonial) => (
              <Carousel.Item key={testimonial.id}>
                <Card className="card-testimonial h-100 text-center position-relative d-flex flex-column">
                  <Card.Body className="d-flex flex-column align-items-center text-center">
                    <Image src={testimonial.photo} alt={`Foto de ${testimonial.name}`} roundedCircle className="mb-4" />
                    <p className="fw-bold mb-2">{testimonial.name}</p>
                    <Card.Text className="mb-4">{testimonial.text}</Card.Text>
                    <div className="d-flex justify-content-center gap-3 mt-auto">
                      {testimonial.social.facebook && (
                        <a href={testimonial.social.facebook} target="_blank" rel="noopener noreferrer" aria-label={`Facebook de ${testimonial.name}`}>
                          <FaFacebook size={24} />
                        </a>
                      )}
                      {testimonial.social.twitter && (
                        <a href={testimonial.social.twitter} target="_blank" rel="noopener noreferrer" aria-label={`Twitter de ${testimonial.name}`}>
                          <FaTwitter size={24} />
                        </a>
                      )}
                      {testimonial.social.linkedin && (
                        <a href={testimonial.social.linkedin} target="_blank" rel="noopener noreferrer" aria-label={`LinkedIn de ${testimonial.name}`}>
                          <FaLinkedin size={24} />
                        </a>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col md={12} className="d-none d-md-block">
          <Row>
            {testimonials.map((testimonial) => (
              <Col lg={4} md={6} className="mb-4" key={testimonial.id}>
                <Card className="card-testimonial h-100 text-center position-relative d-flex flex-column">
                  <Card.Body className="d-flex flex-column align-items-center text-center">
                    <Image src={testimonial.photo} alt={`Foto de ${testimonial.name}`} roundedCircle className="mb-4" />
                    <p className="fw-bold mb-2">{testimonial.name}</p>
                    <Card.Text className="mb-4">{testimonial.text}</Card.Text>
                    <div className="d-flex justify-content-center gap-3 mt-auto">
                      {testimonial.social.facebook && (
                        <a href={testimonial.social.facebook} target="_blank" rel="noopener noreferrer" aria-label={`Facebook de ${testimonial.name}`}>
                          <FaFacebook size={24} />
                        </a>
                      )}
                      {testimonial.social.twitter && (
                        <a href={testimonial.social.twitter} target="_blank" rel="noopener noreferrer" aria-label={`Twitter de ${testimonial.name}`}>
                          <FaTwitter size={24} />
                        </a>
                      )}
                      {testimonial.social.linkedin && (
                        <a href={testimonial.social.linkedin} target="_blank" rel="noopener noreferrer" aria-label={`LinkedIn de ${testimonial.name}`}>
                          <FaLinkedin size={24} />
                        </a>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="section-content text-center">
        <Col>
          <Button className="btn-prim mt-3">¿Compartes la visión? Únete a Psyder y empieza a evolucionar la psicoterapia</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Testimonials;
